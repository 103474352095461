import React, { Fragment, Component } from 'react'
import { createClient } from 'contentful-management'
import styled from '@emotion/styled'
import { typography } from 'src/styles'
import ParticipateSection from 'src/components/ParticipateSection'
import ComponentRenderer from 'src/components/ComponentRenderer'
import TextLockup from 'src/components/TextLockup'
import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import Input from 'src/components/Input'
import Select from 'src/components/Select'
import Checkbox from 'src/components/Checkbox'
import { util, colors } from 'src/styles'
import { validateEmail, validatePhoneNumber } from 'src/utils/validations'
import MaterialIcon from 'src/components/MaterialIcon'
import axios from 'axios';

const SectionText = styled(TextLockup)`
	h1 {
		font-family: ${typography.primaryFont};
		margin-bottom: 50px;
	}
	${ util.responsiveStyles('padding-bottom', 50, 50, 50, 52) };

`

const Form = styled.form`
  display: inline-block;
  vertical-align: top;
`

const StyledInput = styled(Input)`
	display: inline-block;
	margin-top: 0;
  margin-bottom: 50px;
  ${ ({ type }) => type === 'hidden' ? `
    display: none;
  ` : `` }
  ${ ({ isValid }) => isValid === false ? `
    input {
      border-color: ${ colors.red };
    }
  ` : `` }
  background: ${colors.white};
  input {
	  border-color: transparent !important;
  }
`

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`

const StyledLabel = styled.p`
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 30px;
`

const FileUploadWrapper = styled(Grid)`
	${ util.responsiveStyles('margin-top', 50, 50, 50, 52) };
	${ util.responsiveStyles('margin-bottom', 50, 50, 50, 52) };

`

const SuccessMessage = styled.div`
	h1 {
		font-family: ${typography.primaryFont};
	}
`

const StyledTocWrapper = styled.div`
	margin-top: 25px;
	background-color: ${colors.textColor};
	padding: 68px 48px;
	position: relative;
	h2 {
		color: ${colors.white};
		font-family: ${typography.primaryFont};
		font-size: 14px;
		line-height: 18px;
		font-weight: 700;
		margin-top: 0;
		margin-bottom: 18px;
	}
`

const StyledP = styled.p`
	color: ${colors.white};
	font-family: ${typography.primaryFont};
	font-size: 14px;
	line-height: 18px;
	margin-top: 0;
	margin-bottom: 18px;
`

const StyledClose = styled.div`
	color: ${colors.white};
	position: absolute;
	top: 15px;
	right: 15px;
	span {
		&:hover {
			cursor: pointer;
		}
	}
`

const StyledImagePreview = styled.img`
	${ ({ filePreview }) => filePreview === false ? `
    display: none;
  ` : `` }
`
var client = createClient({
  accessToken: process.env.GATSBY_CONTENTFUL_MANAGEMENT_ACCESS_TOKEN
})



class ParticipateUgcForm extends React.Component {
	constructor (props) {
    super(props)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.state = {
      status: '',
      firstName: '',
      lastName: '',
      comment: '',
      file: '',
      email: '',
      instagramHandle: '',
      twitterHandle: '',
      updates: false,
      toc: false,
      showToc: false,
      filePreview: false
    }
  }

	componentDidUpdate (prevProps, prevState, snapshot) {
		
	}

  handleInput = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleFileUpload = event => {
  	// console.log(event.target.files[0])
  	// this.setState({file: event.target.files[0]})
  	const file = event.target.files[0]

  	const xhr = new XMLHttpRequest();
		xhr.onreadystatechange = () => {
		  if (xhr.readyState === 4) {
		    if (xhr.status === 200 || xhr.status === 201) {
		      let data = JSON.parse(xhr.response);
		      // resolve(JSON.parse(xhr.response));
		      console.log(xhr.response)
		      this.createAsset(data, file)
		    } else {
		      // reject(xhr.response);
		      console.log(xhr.response)
		    }
		  }
		};

		const url = "https://upload.contentful.com/spaces/" + process.env.GATSBY_CONTENTFUL_SPACE + "/uploads";
		xhr.open("POST", url, true);
		xhr.setRequestHeader("Content-Type", "application/octet-stream");
		xhr.setRequestHeader("Authorization", "Bearer " + process.env.GATSBY_CONTENTFUL_MANAGEMENT_ACCESS_TOKEN);
		// const formData = new FormData();
		// formData.append("file", file, file.name);
		xhr.send(file);

		this.setState({filePreview: true})
		var imagePreview = document.getElementById('imagePreview');
    imagePreview.src = URL.createObjectURL(event.target.files[0]);
    imagePreview.onload = function() {
      URL.revokeObjectURL(imagePreview.src) // free memory
    }
  }

  createAsset = (response, file) => {
  	// console.log(response)
  	const data = {
			fields: {
			  file: {
			    "en-US": {
			      contentType: file.type,
			      fileName: file.name,
			      uploadFrom: {
			        sys: {
			          type: "Link",
			          linkType: "Upload",
			          id: response.sys.id
			        }
			      }
			    }
			  }
			}
		}
		// console.log("data::",data);

		const request = {
			headers: {
				'Authorization': "Bearer " + process.env.GATSBY_CONTENTFUL_MANAGEMENT_ACCESS_TOKEN
			},	
	    method: "POST",
			body: JSON.stringify(data)
		}
		fetch("https://api.contentful.com/spaces/"+ process.env.GATSBY_CONTENTFUL_SPACE +"/assets", request)
		.then(response => {
			return response.json()
		})
		.then(json => {
			console.log(json)
			this.processAsset(json);
		}).catch(function(error) {
      console.error(error);
    });

  }

  processAsset = (asset) => {
  	console.log("asset:", asset);

  	const request = {
			headers: {
				'Authorization': "Bearer " + process.env.GATSBY_CONTENTFUL_MANAGEMENT_ACCESS_TOKEN
			},	
	    method: "PUT",
		}
		fetch("https://api.contentful.com/spaces/"+ process.env.GATSBY_CONTENTFUL_SPACE +"/assets/" + asset.sys.id + "/files/en-US/process", request)
		.then(response => {
			// console.log("response ::::",response)
		})
		.catch(function(error) {
      console.error(error);
    });

    this.setState({file: asset.sys.id})
  }

  handleCheckboxChange = changeEvent => {
	  const { name } = changeEvent?.target;
		if (name) {
			this.setState(prevState => ({
				[name]: !prevState[name]
			}));
		}
	};

  showHideToc = () => {
		this.setState(prevState => ({
		  showToc: !prevState.showToc
		}));
	}

	createContentfulEntry = (event) => {
		event.preventDefault();
		// console.log(this.state);
		client.getSpace(process.env.GATSBY_CONTENTFUL_SPACE)
		.then((space) => space.getEnvironment(process.env.GATSBY_CONTENTFUL_ENVIRONMENT))
		.then((environment) => environment.createEntry('ugcItem', {
		  fields: {
		  	internalName: {
		  		'en-US': this.state.firstName + " " + this.state.lastName
		  	},
		    firstName: {
		      'en-US': this.state.firstName
		    },
		    lastName: {
		      'en-US': this.state.lastName
		    },
		    comment: {
		      'en-US': this.state.comment
		    },
		    email: {
		      'en-US': this.state.email
		    },
		    instagramHandle: {
		      'en-US': this.state.instagramHandle
		    },
		    twitterHandle: {
		      'en-US': this.state.twitterHandle
		    },
		    updates: {
		      'en-US': this.state.updates
		    },
		    image: {
		      'en-US': {
              'sys': {
                  'id': this.state.file,
                  'linkType': 'Asset',
                  'type': 'Link',
              }
            }
		    },
		  }
		}))
		.then((entry) => {
			console.log(entry)
			this.setState({status: 'SUCCESS'})
		})
		.catch(function(error) {
      console.error(error);
      this.setState({ status: 'ERROR' })
    });
	}

  render () {
		const { pageType, className, sectionHeader } = this.props
    const { status, firstName, lastName, comment, file, email, instagramHandle, twitterHandle, updates, toc, showToc, fileReady } = this.state
    const valid = firstName !== '' && lastName !== '' && email !== '' && updates !== false && toc !== false && validateEmail(email) 

			return (
				<Fragment>
					{ status === 'SUCCESS' ? (
						<SuccessMessage>
							<h1>Thank You!</h1>
							<p>We appreciate your time completing this survey to help save the Tidal Basin for generations to come. You can further participate in this project by signing the pledge.</p>
						</SuccessMessage>
					) : (
						<Fragment>
							<SectionText
		            text={sectionHeader}
		          />
							<Form
				        onSubmit={this.createContentfulEntry}
				        // action="https://formspree.io/mjvpbera"
				        // method="POST"
				      >
				      	<StyledLabel>Your Name</StyledLabel>
				      	<Grid small="[1]" large="[1] [1]" colGap="16px">
					        <StyledInput
					          onChange={this.handleInput}
					          type="text"
					          name="firstName"
					          label="First Name"
					          value={firstName}
					        />

					        <StyledInput
					          onChange={this.handleInput}
					          type="text"
					          name="lastName"
					          label="Last Name"
					          value={lastName}
					        />
				        </Grid>

				        <StyledLabel>Tell us why you want to #SaveTheTidalBasin</StyledLabel>
				        <StyledInput
					          onChange={this.handleInput}
					          type="text"
					          name="comment"
					          label="Text here..."
					          value={comment}
					        />

					      <FileUploadWrapper small="[1]" large="[1] [1]" colGap="16px">
					      	<div>
						        <StyledLabel>If you would like to include a picture, please attach it here (optional):</StyledLabel>
					      	</div>
					      	<div>
					        <input
					          onChange={this.handleFileUpload}
					          type="file"
					          name="file"
					          label="Image"
					        />
					        <StyledImagePreview id="imagePreview" filePreview={this.state.filePreview} />
					        </div>

				        </FileUploadWrapper>

				        <StyledLabel>Email address</StyledLabel>
				        <StyledInput
					          onChange={this.handleInput}
					          type="text"
					          name="email"
					          label="Email"
					          value={email}
				        />

				        <Grid small="[1]" large="[1] [1]" colGap="16px">
				        	<div>
					        	<StyledLabel>Instagram Handle <br/>(if you would like us to tag you on social media)</StyledLabel>
						        <StyledInput
						          onChange={this.handleInput}
						          type="text"
						          name="instagramHandle"
						          label=""
						          value={instagramHandle}
						        />
					        </div>

					        <div>
					        	<StyledLabel>Twitter Handle <br/>(if you would like us to tag you on social media)</StyledLabel>
						        <StyledInput
						          onChange={this.handleInput}
						          type="text"
						          name="twitterHandle"
						          label=""
						          value={twitterHandle}
						        />
					        </div>
				        </Grid>

				        <Checkbox 
				        	name="updates"
				        	label="Keep me updated with news from Tidal Basin"
							    isSelected={this.state.updates}
							    onCheckboxChange={this.handleCheckboxChange}
				        />

				        <Checkbox 
				        	name="toc"
				        	label={<span>By checking this box, I agree to the <a onClick={this.showHideToc}>terms and conditions</a> of submitting my content.</span>}
							    isSelected={this.state.toc}
							    onCheckboxChange={this.handleCheckboxChange}
				        />

				        {this.state.showToc ? (

				        	<StyledTocWrapper>
				        		<StyledClose onClick={this.showHideToc}><MaterialIcon>close</MaterialIcon></StyledClose>
				        		<h2>Terms and Conditions – National Mall Tidal Basin Ideas Lab</h2>
				        		<StyledP>Updated as of October 5, 2020</StyledP>
				        		<StyledP>By submitting information and/or a photograph (the “Materials”) to the National Mall Tidal Basin Ideas Lab (the “Program”), you (the “Participant”) agree to the following Terms and Conditions (the “Terms and Conditions” or the “Agreement”) for the Program, which is managed by the National Trust for Historic Preservation in the United States (the “National Trust”) and the Trust for National Mall (the “TMN” and together with the National Trust, the “Sponsors”).</StyledP>
				        		<StyledP>1.	License to Use Participant’s Materials. The Participant has submitted Materials to the Sponsors for use with the Program. The Participant owns and retains the copyright to the Materials and grants to each of the Sponsors a perpetual, royalty-free, non-exclusive, world-wide, transferable, unconditional, and sublicensable right to use, copy, modify, distribute, reproduce, and publish the Materials, without any further consent, notice and/or compensation to the Participant, in any and all media in connection with the Program and/or for editorial, commercial, promotional, or trade purposes related to the Program and/or the Sponsors.  The Sponsors may each use the Participant’s email address and/or other personal information collected through the Program in accordance with the Privacy Policy of the Sponsors.  Further, the Sponsors reserve the right, in their sole discretion, to use or not use the Materials in the Program. Submission of Materials does not guarantee participation in the Program. The Sponsors reserve the right to discontinue the Program at any time. By submitting Materials, the Participant agrees to participate in the Program subject to these Terms and Conditions.</StyledP>
				        		<StyledP>2.	Representations and Warranties. The Participant represents and warrants that: (a) it has the right to enter into this Agreement and grant all rights and perform all obligations in this Agreement; (b) the Participant is the copyright owner of the Materials or authorized to act on behalf of the copyright owner; and (c) the Materials do not violate any law nor anyone’s rights (including intellectual property rights and privacy rights) and are true and accurate to the best of the Participant’s knowledge. This provision will survive termination of this Agreement.</StyledP>
				        		<StyledP>3.	Information Sharing. The Participant agrees to make reasonable efforts to respond to the Sponsors’ requests for additional information or photographs for the Program.<br/> Representative Notices. The National Trust’s representative is: Julia Rocchi, (202) 588-6047, jrocchi@savingplaces.org. The representative is the contact person specified in the submission of the Materials. The Trust for the National Mall’s representative is: Jeremy Flick, (202) 407.9415, jflick@nationalmall.org.  The Parties agree that notices and messages will be sent via e-mail to the representatives. The Parties agree to keep their contact information up to date.</StyledP>
				        		<StyledP>4.	Release and Indemnification. The Participant agrees to defend, indemnify, and hold harmless the Sponsors and their trustees, officers, employees, agents, affiliates, sublicensees, successors, and assigns from and against all claims, causes of action, demands, losses, damages, fines, and penalties and/or other costs or expenses of any kind or nature (including without limitation reasonable attorneys’ fees) brought by any thirty party as a result of or due to: (a) any claims or causes of action arising, in whole or in part, from or attributable to the use of the Materials by the Sponsors; and (b) the Participant’s breach of this Agreement, including without limitation a breach of the representations and warranties made in this Agreement. This provision will survive termination of this Agreement.</StyledP>
				        		<StyledP>5.	Changes. The Sponsors may modify these Terms and Conditions or the Program from time to time. Updates to these Terms and Conditions will be published online. If the Participant objects to any changes, the Participant may terminate this Agreement as specified below. The Participant’s continued participation in the Program after the changes are published or a notice is sent about the changes to these Terms and Conditions means that the Participant consents to the changes.</StyledP>
				        		<StyledP>6.	Termination. The National Trust may terminate this Agreement at any time by removing the Materials from the Program. On termination, the Participant will no longer be a participant in the Program and understands that the Materials will not be included in the Program.</StyledP>
				        		<StyledP>7.	Miscellaneous. If a court with authority over this Agreement finds any part of it unenforceable, the Parties agree that the court should modify the terms to make that part enforceable while still achieving its intent. If the court cannot do that, the Parties agree to ask the court to remove that unenforceable part and still enforce the rest of this Agreement. This Agreement is the only agreement between the Parties regarding the Program and supersedes all prior agreements related to the Program. The Participant agrees to comply with all laws, rules, or regulations. The Participant may not assign or transfer this Agreement without the prior written consent of the Sponsors. There are no third-beneficiaries to this Agreement. If the Sponsors do not act to enforce a breach of this Agreement, that does not mean that the Sponsors have waived their rights to enforce this Agreement. In the unlikely event the Parties end up in a legal dispute, the Parties agree to resolve it in the courts of the District of Columbia using the laws of the District of Columbia and Federal law, as applicable.</StyledP>
				        	</StyledTocWrapper>

			        	) : false}

				        <div style={{ paddingTop: '16px' }}>
				          <Button type="submit" disabled={!valid} setTheme="textColor">
				            {status === 'SUCCESS' ? 'Thank You' : 'Submit'}
				          </Button>
				        </div>
				      </Form>
			      </Fragment>
					)}
					
	      </Fragment>
			)
	}

	
}

// ParticipateUgcForm.defaultProps = {
// 	status: 'SUCCESS'
// }

export default ParticipateUgcForm